<template>
	<div class="mypage_content_wrap">
		<h2>회원탈퇴</h2>
		<div class="mypage_content_box_wrap">
			<div class="mypage_content_box">
				<div class="mypage_tit_wrap">
					<h4>회원 탈퇴 신청전 다음 주의사항을 꼭 확인해 주세요.</h4>
				</div>
				<div class="form_box_wrap">
					<ul class="caution">
						<li>탈퇴한 아이디(이메일)는 재가입 및 복구가 불가능합니다.</li>
						<li>
							서비스 이용 기과 구매하신 콘텐츠, 보유하신 잔여 캐시 및 쿠폰은 모두 삭제됩니다.
							<ul class="caution_depth">
								<li>회원님의 개인 기록은 모두 삭제되며 삭제된 데이터는 복구되지 않습니다.</li>
								<li>
									사용하지 않은 캐시에 대해서는 환불이 가능하며, 보너스 캐시는 환불 대상이 아닙니다.
								</li>
							</ul>
						</li>
						<li>탈퇴 후 댓글은 삭제되지 않습니다.</li>
					</ul>
				</div>
			</div>
			<div class="mypage_content_box">
				<div class="mypage_tit_wrap">
					<h4>탈퇴하려는 이유가 무엇인가요?</h4>
				</div>
				<div class="form_box_wrap border_none">
					<select name="birth" id="birth-select" v-model="secesFeed" class="mgb0">
						<option disabled value="">탈퇴 사유를 선택해주세요.</option>
						<option value="1">플랫폼 사용이 불편함</option>
						<option value="2">컨텐츠의 양과 질이 떨어짐</option>
						<option value="3">기타 사항</option>
					</select>
				</div>
				<div class="form_box_wrap">
					<textarea
						id="secesContent"
						rows="5"
						cols="33"
						:disabled="secesFeed != 3"
						v-model="secesContent"
						placeholder="기타 사유를 입력해주세요."
						class="mgb0"
					></textarea>
				</div>
			</div>
			<div class="mypage_content_box">
				<div class="mypage_tit_wrap" style="flex-direction: column; align-items: start">
					<h4>본인 확인을 위해 비밀번호를 입력해주세요.</h4>
					<span style="color: red; font-size: 11px"
						>SNS 로그인 유저는 비밀번호 입력이 필요하지 않습니다!</span
					>
				</div>
				<div class="form_box_wrap">
					<input
						:disabled="jwt.getToken('loginType') != 'normal'"
						v-model="password"
						type="text"
						id="name"
						name="name"
						placeholder="비밀번호를 입력해주세요."
						class="mgb0"
					/>
				</div>
			</div>
		</div>
		<div class="agreement_bg_wrap">
			<div class="agreement_check_box_wrap">
				<div class="agreement_check_box">
					<input type="checkbox" v-model="agreeTerm" class="g_check" id="g_check6" /><label for="g_check6"
						>회원 탈퇴에 대한 주의사항을 모두 확인하였으며, 모든 내용에 동의합니다.</label
					>
				</div>
			</div>
		</div>
		<div class="btn_wrap_line">
			<button type="button" class="btn_basic btn_grey_bg">취소</button>
			<button @click="secessionProc" type="button" class="btn_basic btn_green_bg">탈퇴</button>
		</div>
	</div>
</template>

<script setup>
import ajax from '@/api/api-manager';
import { userInfoStore } from '@/store/UserStore';
import { onMounted, ref, watch } from 'vue';
import jwt from '@/api/LocalStorage';
import { useRouter } from 'vue-router';
import { getLoginInfo, checkLogin } from '@/common-methods/checkAccount';

const router = useRouter();

// 로그인 여부 체크
const loginState = checkLogin();
if (!loginState) {
	alert('로그인 후 이용 가능');
	router.replace('/');
}

// 유저 정보 스토어
const store = userInfoStore();

// 탈퇴 사유
const secesFeed = ref('');

// 기존 비밀번호 확인용
const password = ref('');

// 기타 사유 피드백
const secesContent = ref('');

// 탈퇴 동의
const agreeTerm = ref(false);

watch(secesFeed, (newData) => {
	if (newData != 3) {
		secesContent.value = '';
	}
});

// 기타
const secessionProc = () => {
	if (secesFeed.value == '') {
		alert('탈퇴 사유를 선택해주세요');
		return false;
	}
	if (!agreeTerm.value) {
		alert('회원 탈퇴 내용에 대한 동의를 체크해주세요');
		return false;
	}

	// api request parameters
	const params = {
		password: password.value, // 기존 비밀번호 체크
		seces_content: secesContent.value, // 기타 사유 내용
		seces_feed: secesFeed.value, // 사유 항목
	};
	if (confirm('정말로 회원탈퇴를 진행하시겠습니까?')) {
		ajax('user/secession', params)
			.then((response) => {
				console.log(response);
				jwt.destroyToken('id_token');
				jwt.destroyToken('id');
				jwt.destroyToken('nickname');
				jwt.destroyToken('userNo');
				jwt.destroyToken('loginType');
				jwt.destroyToken('membership');
				jwt.destroyToken('kakao');
				jwt.destroyToken('naver');
				alert('회원탈퇴가 완료되었습니다');
				window.location.href = '/';
			})
			.catch((error) => {
				if (error.response.data.code == 'M003') {
					alert('기존 비밀번호가 일치하지 않습니다!');
					console.log(error.response);
					return false;
				} else {
					alert('회원탈퇴를 완료하지 못했습니다. 다시 시도해주세요');
					return false;
				}
			});
	} else {
		return false;
	}
};
</script>
